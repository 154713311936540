<!--  个人信息-->
<template>
  <div>
    <div class="data" v-loading="loading">
      <div class="herder_box">
        <p>个人资料</p>
        <div class="herder">
          <el-upload
              ref="upload"
              :action="''"
              :http-request="handleUpload"
              :multiple="false"
              :on-success="handleAvatarSuccess"
              :show-file-list="false"
              accept="image/jpg,image/png,image/gif,image/bmp,image/jpeg"
              style="display: none;" >
          </el-upload>
          <el-avatar class="img" :size="60" :src="form.headimg"></el-avatar>
          <el-button type="text" @click="handleCommand('choose')">
            <p>更换头像</p>
          </el-button>
        </div>
      </div>
      <div class="dataForm" style="margin-top: 20px;">
        <div class="title">
          <p>基本信息</p>
        </div>
        <form-create v-model="form" :components="components" @update="handleUpdate" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="right" :inline="false">
          <el-form-item slot="after">
            <div class="btn">
              <el-button type="primary" @click="handleSubmit">保存</el-button>
              <el-button @click="$refs.ruleForm.reset()">重置</el-button>
            </div>
          </el-form-item>
        </form-create>
      </div>
    </div>
  </div>
</template>

<script>
import { update_info } from "@/api/me.js"
import tool from "@/Utils/tool";

export default {
  data () {
    var _this = this;
    //这里存放数据
    return {
      loading: false,
      components:[
	  {name:'el-row' ,components:[
            {name:'el-col',props:{span:12},components:[
				{name:'form-item',props:{prop:'nickname',label:'昵称',required:true}},
				{name:'form-item',props:{prop:'realname',label:'真实姓名',required:true}},
              ]},
            {name:'el-col',props:{span:12},components:[
				{name:'form-item',props:{prop:'create_time',label:'注册时间',disabled:true,type:'dateTime'}},
              ]}
          ]
        },
        {name:'form-item',props:{
            prop:'sex',label:'性别',type:'radio',required:true,defaultValue:'保密',data:[
              {label:'男',value:'男'},
              {label:'女',value:'女'},
              {label:'保密',value:'保密'},
            ]
          }
        },
        {name:'form-item',props:{prop:'area',label:'现居地',type:'area',required:true}},
        {name:'form-item',props:{prop:'address',label:'详细地址',type:'textarea',required:true,maxlength:100, showWordLimit:true ,rows:2}},
        {name:'form-item',props:{prop:'birth',label:'出生年月',required:true, rules:[ { validator(rule, value, callback){if(value && (new Date(value)).getDate() !== parseInt(value.split('-')[value.split('-').length - 1])){callback(new Error('出生年月不正确'))}}, trigger: 'change'}]},components:[
            {name:'el-row' ,components:[
                {name:'el-col',props:{span:3},components:[
                    {name:'form-item',props:{prop:'birth_year',label:'出生年',itemType:0,required:true,type:'select'},callback(d){d.props.data = _this.dates.years;}},
                  ]},
                {name:'el-col',props:{span:3,offset:1},components:[
                    {name:'form-item',props:{prop:'birth_month',label:'出生月',itemType:0,required:true,type:'select'},callback(d){d.props.data = _this.dates.months;}}
                  ]},
                {name:'el-col',props:{span:3,offset:1},components:[
                    {name:'form-item',props:{prop:'birth_day',label:'出生日',itemType:0,required:true,type:'select'},callback(d){d.props.data = _this.dates.days;}}
                  ]},
              ]},
          ]
        },
        {name:'form-item',props:{prop:'school_name',label:'学校',required:true}},
        {name:'form-item',props:{prop:'grade_name',label:'年级',required:true}},
        {name:'form-item',props:{prop:'class_name',label:'班级',required:true}},
        {name:'form-item',props:{prop:'educate_id',label:'教育Id',required:true}},
        // {name:'form-item',props:{prop:'description',label:'个人简介',type:'textarea',required:true}},
        {name:'form-item',props:{prop:'description',label:'个人简介',type:'editor',editorConfig:'title',required:true}},
        {name:'form-item',props:{prop:'professional_skill',label:'专业技能',type:'tag',required:true,rules:[{required:true,message:'请添加专业技能'}]}},
      ],
      form:{},
      dates:{
        years:[],
        months:[],
        days:[],
      },
    }
  },
  //监控data中的数据变化
  watch: {
    userInfo:{
      immediate:true,
      deep:true,
      handler(v,o){
        if(!tool.obj_equal(v,o)){
          this.initForm(v);
        }
      },
    }
  },
  //方法集合
  methods: {
    routeUpdate(){
      this.initForm(Object.assign({}, (this.userInfo || {}).id ? this.userInfo : {}, this.$route.params , this.$route.query ));
    },
    initForm(obj , isForce = false){
      let {password,birth,province,city,area,address , ...data} = obj || {};
      let births = (birth || '').split('-')
      this.initDates(births[0] || 0,births[1] || 0);
      let $password = password ? tool.decrypt(password, 'password') : '';
      let $area = [province,province && province === city ? area : city,province && province === city ? null :area].filter(d=>d)
      if($area.length > 0)address = (address || '').replace($area.join(''),"")
      if(isForce){
        this.form = Object.assign({}, data , {password:$password,area:$area,address,birth,birth_year:births[0] || '',birth_month:births[1] || '',birth_day:births[2] || ''} )
      }else{
        Object.assign(this.form , data , {password:$password,birth,area:$area,address,birth_year:births[0] || '',birth_month:births[1] || '',birth_day:births[2] || ''} )
      }
    },
    initDates(year , month){
      year = year || this.form.birth_year || 0
      month = month || this.form.birth_month || 0
      //获取年
      let date = new Date()
      let years = [];
      let current_year = date.getFullYear()
      for (let i = 0; i < 100; i++) {
        let y = current_year - i
        years.push(y+"")
      }
      let months = [];
      //获取月
      for (let i = 1; i < 13; i++) {
        if (i < 10) {
          months.push("0" + i)
        } else {
          months.push(i+"")
        }
      }
      let days = [];
      let day = new Date(year, parseInt(month), 0).getDate()
      for (let i = 1; i <= day; i++) {
        if (i < 10) {
          days.push("0" + i)
        } else {
          days.push(i+"")
        }
      }
      this.dates = {years ,months,days};
    },
    handleUpdate({prop}){
      if(prop && ['birth_year','birth_month'].indexOf(prop) > -1){
        this.initDates(this.form.birth_year,this.form.birth_month);
        if(this.form.birth_day && this.dates.days.indexOf(this.form.birth_day) === -1){this.form.birth_day = null;}
      }
      if(this.form.birth_year && this.form.birth_month && this.form.birth_day){
        this.form.birth = [this.form.birth_year , this.form.birth_month , this.form.birth_day].join('-')
      }else{
        this.form.birth = '';
      }
      this.$emit('change' , this.form)
    },
    handleAvatarSuccess (res, file) {
      this.form = {...this.form,headimg:res.data.url};
    },
    handleSubmit(){
      this.loading = true;
      this.$refs.ruleForm.validate((form , valid , errors)=>{
        if(valid){
          let {area , address , ...other} = form
          address = (area || []).join('')+address
          this.$http.post('/xapi/user/updateUser' , {...other,address,headimg:this.form.headimg}).then(({data,msg})=>{
            this.$store.commit('SET' , {key:'userInfo' ,value:Object.assign({} , this.userInfo , {refresh:true})})
            this.$handleMessage(msg || '个人资料更新成功' , 'success' );
          }).finally(()=>{
            this.loading = false;
          });
        }else if(errors.length > 0){
          this.loading = false;
          this.$handleMessage(errors[0].message , 'error');
        }else{
          this.loading = false;
        }
      } , true);
    },
    handleCommand(command ){
      if(command === 'choose' && this.$refs.upload){
        this.$jquery(this.$refs.upload.$el).find('[name=file]').trigger('click');
      }
    },
    handleUpload({ data , file , filename , headers , onError , onProgress , onSuccess , withCredentials}){
      data = {...this.$route.params,...data};
      let formdata = new FormData();
      formdata.append(filename || 'file', file);
      for (const dk in data) {
        formdata.append(dk, data[dk]);
      }
      this.$http.request({
        url : this.$store.state.action,
        data : formdata,
        method:'post',
        headers : headers || {},
        withCredentials:withCredentials === true,
        onUploadProgress:function(event){
          onProgress(event);
          // onProgress(event.loaded/event.total*100);
        }
      }).then(function(res){
        onSuccess(res);
      }).catch(onError)
    },
  },
};
</script>
<style  lang='less' scoped>
::v-deep {
  .el-input__prefix {
    display: none;
  }
  .el-textarea__inner{
    font-family: auto !important;
    padding:5px 10px !important;

  }
}
.el-icon-circle-plus-outline,
.el-icon-remove-outline {
  font-size: 24px;
  line-height: 32px;
  cursor: pointer;
}
.data {
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 2.5%;
  .herder_box {
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      line-height: 33px;
      margin-left: -1%;
    }
    .herder {
      display: flex;
      align-items: center;
      margin: 4% 0 0 1%;
      .img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
      }
      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #191919;
        line-height: 20px;
      }
    }
  }
  .title {
    padding: 0% 0 1.5% 1%;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #191919;
    line-height: 25px;
    border-bottom: 1px solid #979797;
    margin-bottom: 2.5%;
  }
  .el-form {
    padding-top: 2.5%;
    display: flex;
    flex-direction: column;
    .ipt_box {
      display: flex;
      .el-input {
        margin-right: 40px;
      }
    }
    .overTime {
      .el-input {
        margin: 0 0 0 4%;
      }
    }
    .usedLabel_text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      line-height: 20px;
      margin-bottom: 2%;
    }
    .usedLabel {
      margin-right: 5%;
      li {
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          width: 100%;
          margin-bottom: 4%;
        }
      }
    }
    .addLabels {
      display: flex;
      li {
        background: #ffffff;
        border-radius: 15px;
        border: 1px solid #979797;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #191919;
        padding: 0 8%;
        height: 30px;
        margin-right: 10%;
      }
    }
    .website {
      display: flex;
      width: 70%;
      margin-bottom: 10px;
      .el-input {
        height: 30px;
        box-sizing: border-box;
        min-width: 100%;
        border-radius: 4px;
      }
    }
    .addLabel {
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #191919;
        line-height: 20px;
        &:nth-child(1) {
          margin-right: 3%;
        }
      }
    }
  }
}
</style>
<style lang="less">
</style>